import type { ComponentType } from "react"
import { useEffect, useState } from "react"

interface WithHrefProp {
    href?: string
}

export function ctaButton<P extends WithHrefProp>(
    Component: ComponentType<P>
): ComponentType<P> {
    return (props) => {
        const [newHref, setNewHref] = useState(props.href || "")

        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search)
            const utm_source = urlParams.get("utm_source") || ""
            const utm_medium = urlParams.get("utm_medium") || ""
            const utm_campaign = urlParams.get("utm_campaign") || ""
            const utm_content = urlParams.get("utm_content") || ""
            const utm_term = urlParams.get("utm_term") || ""

            const parameters: string[] = []

            if (utm_source) {
                parameters.push(`utm_source=${utm_source}`)
            }

            if (utm_medium) {
                parameters.push(`utm_medium=${utm_medium}`)
            }

            if (utm_campaign) {
                parameters.push(`utm_campaign=${utm_campaign}`)
            }

            if (utm_content) {
                parameters.push(`utm_content=${utm_content}`)
            }

            if (utm_term) {
                parameters.push(`utm_term=${utm_term}`)
            }

            const utmParameters =
                parameters.length > 0 ? `?${parameters.join("&")}` : ""
            const updatedHref = `${window.location.origin}/et-contact${utmParameters}`
            setNewHref(updatedHref)
        }, [])

        const handleRedirect = () => (window.location.href = newHref)

        return (
            <div onClick={handleRedirect}>
                <Component {...props} href={newHref} />
            </div>
        )
    }
}
